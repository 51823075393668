import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import Vuelidate from 'vuelidate';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Vue.use(VueGtag, {
    config: { id: 'UA-68174800-6' },
});

new Vue({
    store,
    render: h => h(App),
}).$mount('#app');
