<template>
    <div class="page-container d-flex justify-content-center align-items-center position-relative" @click="start">
        <div class="wrapper">
            <div>
                <img src="../assets/circul-8-logo.svg" class="centerContent circul8" />
                <div class="lottieWrapper">
                    <Lottie v-bind:options="{ loop: true, autoplay: true }" />
                </div>
                <h2 class="centerContent vision">VISION</h2>
                <!--h3 class="subheading">THE MARDI GRAS EDITION</h3-->
                <p class="subcontent" v-html="data.content" />
                <h5 class="clickAnywhere centerContent">{{ data.calltoaction }}</h5>
            </div>
        </div>
        <!--div class="ribbon">
            <div class="ribbon__band ribbon__band--red"></div>
            <div class="ribbon__band ribbon__band--orange"></div>
            <div class="ribbon__band ribbon__band--yellow"></div>
            <div class="ribbon__band ribbon__band--green"></div>
            <div class="ribbon__band ribbon__band--blue"></div>
            <div class="ribbon__band ribbon__band--purple"></div>
        </div-->
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Lottie from './Lottie.vue';
import data from '../content';

export default {
    name: 'FirstPage',
    components: {
        Lottie,
    },
    data() {
        return {
            data: data.find(item => item.page === 1),
        };
    },
    methods: {
        ...mapMutations(['incrementPage']),
        start() {
            this.$gtag.event('start');
            this.incrementPage();
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/default.scss';

.page-container {
    background-color: #000;
    cursor: pointer;
    overflow: hidden;
}

.centerContent {
    text-transform: uppercase;
}
.vision {
    font-family: PublicaSlab-Bold;
    letter-spacing: 12px;
    text-align: center;
    color: #ffffff;
    font-size: 2.2rem;
}
.subheading {
    font-family: PublicaSlab-Medium;
    font-size: 1.6rem;
    color: #FF1C96;
    margin-top: 6vh;
}
.clickAnywhere {
    width: 384px;
    height: 17px;
    font-family: PublicaSlab-Bold;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    color: #10e5bb;
}
.subcontent {
    width: 325px;

    font-family: Arimo-Regular;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 6vh auto 12vh;
}
.centerContent {
    margin: 0 auto;
}
.circul8 {
    width: 23vh;
    margin-bottom: 19px;
    display: block;
}
.lottieWrapper {
    width: 42vh;
    object-fit: contain;
    margin-bottom: 19px;
    display: block;
    margin: 0 auto 29px auto;
}

.ribbon {
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    z-index: 100;
    transform: rotate(-45deg);
    transform-origin: top center;
    &__band {
        width: 100%;
        height: 55px;
        &--red {
            background-color: $red;
        }
        &--orange {
            background-color: $orange;
        }
        &--yellow {
            background-color: $yellow;
        }
        &--green {
            background-color: $green;
        }
        &--blue {
            background-color: $blue;
        }
        &--purple {
            background-color: $purple;
        }
    }
}

@media (min-width: 1024px) {
    .circul8 {
        width: 204px !important;
        margin-bottom: 39px !important;
    }
    .lottieWrapper {
        width: 400px;
        margin-bottom: 19px;
    }
    .mainImg {
        width: 48vh !important;
        margin-bottom: 0px !important;
    }
    .vision {
        width: 293px;

        font-size: 60px;
        font-weight: bold;
    }
    .subcontent {
        font-size: 20px !important;
        width: 384px !important;
    }
}

@media (max-width: 600px) {
    .circul8 {
        margin-bottom: 31px !important;
    }
    .lottieWrapper {
        max-width: 259px !important;
        margin-bottom: 29px !important;
    }
    .vision {
        font-size: 40px !important;
    }
    .subcontent {
        max-width: 275px !important;
        font-size: 20px !important;
    }
    .clickAnywhere {
        max-width: 180px;
    }
    .ribbon__band {
        height: 25px;
    }
    .subheading {
        font-size: 1.4rem;
    }
}
</style>
