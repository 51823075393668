<template>
    <div id="app">
        <FirstPage v-if="this.page === 1" />
        <SecondPage v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Vue2TouchEvents from 'vue2-touch-events';
import Vue from 'vue';

import FirstPage from './components/FirstPage.vue';
import SecondPage from './components/SecondPage.vue';

Vue.use(Vue2TouchEvents);

export default {
    name: 'app',
    components: {
        FirstPage,
        SecondPage,
    },
    computed: mapState({
        content: state => state.content,
        displayForm: state => state.displayForm,
        contentAlias: ['content', 'displayForm'],
        page: state => state.page,
    }),
    methods: {},
    created() {},
    destroyed() {},
};
</script>

<style lang="scss">
@font-face {
    font-family: 'PublicaSlab-Bold';
    src: url('./assets/fonts/PublicaSlab-Bold.eot');
    src: url('./assets/fonts/PublicaSlab-Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/PublicaSlab-Bold.woff') format('woff'),
        url('./assets/fonts/PublicaSlab-Bold.ttf') format('truetype'); /*, url('fontname.svg#fontname') format('svg');*/
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PublicaSlab-Medium';
    src: url('./assets/fonts/PublicaSlab-Medium.eot');
    src: url('./assets/fonts/PublicaSlab-Medium.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/PublicaSlab-Medium.woff') format('woff'),
        url('./assets/fonts/PublicaSlab-Medium.ttf') format('truetype'); /*, url('fontname.svg#fontname') format('svg');*/
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Arimo-Regular';
    src: url('./assets/fonts/Arimo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Arimo-Bold';
    src: url('./assets/fonts/Arimo-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
#app {
    font-family: 'Arimo-Regular', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
    height: 100vh;
    cursor: pointer;
}
.page-container {
    width: 100%;
    height: 100%;
}
.darkBackground {
    background-color: #000000;
}
.wrapper {
    margin: 0;
    width: 100%;
    position: relative;
    z-index: 2;
}
</style>
