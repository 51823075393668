const data = [
    {
        page: 1,
        content: 'What will 2020 look like?<br> See for yourself.',
        calltoaction: 'Take the test',
    },
    {
        page: 2,
        header: 'SAYYESMOREOFTEN',
        content: 'It leads to 10/10 ideas, 9/10 times.',
        attribution: 'Circul8',
    },
    {
        page: 3,
        header: 'ONEDOGPERPERSON',
        content: 'Achieve a daily ratio of 1:1 dogs to humans in the office.',
        attribution: 'Circul8',
    },
   {
        page: 4,
        header: 'ABANDONTHEQUEEN',
        content: 'Chuck a megxit and break away from old conventions.<br>Even at the risk of screwing up royally.',
        attribution: 'Circul8',
    },
{
        page: 5,
        header: 'GETFIREDUPPEEPS',
        content: 'Nothing like a crisis to push the burning question: <br>What are we doing about climate change?',
        attribution: 'Circul8',
    },
   {
        page: 6,
        header: 'BEMORECHILDLIKE',
        content: 'Except for creative tantrums.',
        attribution: 'Circul8',
    },
    {
        page: 7,
        header: 'MARIEKONDOSTUFF',
        content: 'Get our shit in order.<br>(That includes our personal lives.)',
        attribution: 'Circul8',
    },
    {
        page: 8,
        header: 'INVENTHOVERDESK',
        content: 'Like the hoverboard in Back to the Future but for productivity.',
        attribution: 'Circul8',
    },
    {
        page: 9,
        header: 'GETOFFYOURPHONE',
        content: 'Thumbs up.',
        attribution: 'Circul8',
    },
    {
        page: 10,
        header: 'SWEATSMALLSTUFF',
        content: 'Learn to see the big picture, pixel by pixel.',
        attribution: 'Circul8',
    },

    {
        page: 11,
        header: 'FIXPOSTUREDAILY',
        content: 'Work towards being an office bunch without the office hunch.',
        attribution: 'Circul8',
    },
    {
        page: 12,
        header: 'WINTENGOLDLIONS',
        content: 'We’ve already made room on the mantle.',
        attribution: 'Circul8',
    },
    {
        page: 13,
        header: 'WELCOMETHECHAOS',
        content: 'Creativity and chaos. You can’t have one without the other.',
        attribution: 'Circul8',
    },
    {
        page: 14,
        header: 'WINTHEPOWERBALL',
        content: '15, 21, 27, 9, 12, 35, 1 and 33.<br>Mark our words.',
        attribution: 'Circul8',
    },
    {
        page: 15,
        header: 'GIVEBACKMONTHLY',
        content: 'First and foremost, we want to be known as a generous agency.',
        attribution: 'Circul8',
    },
    {
        page: 16,
        header: 'LOCATEHOLYGRAIL',
        content: 'It’s out there. Monty Python were onto something.',
        attribution: 'Circul8',
    },
    {
        page: 17,
        header: 'BEMORELIKEMOANA',
        content: 'Complete our mission:<br>Ban single use plastics on every Australian beach.',
        attribution: 'Circul8',
    },
    {
        page: 18,
        header: 'GETWAYMORESLEEP',
        content: 'Let’s aim for at least 7 hours, not including naps.',
        attribution: 'Circul8',
    },

    {
        page: 19,
        header: 'SEASTHEDAY',
        content: 'Put on that resting beach face',
        attribution: 'Rachel - Changi Airport',
    },
    {
        page: 20,
        header: 'WORKLIFEBALANCE',
        content: 'Work more, live less. Hang on...',
        attribution: 'Belinda - UNKO',
    },
    {
        page: 21,
        header: 'GRABBULLBYHORNS',
        content: 'Take on every new opportunity',
        attribution: 'Laura - DeSireBull',
    },
    {
        page: 22,
        header: 'PLAN$AHEAD',
        content: 'Retire early',
        attribution: 'Taylor - Vrbo',
    },
    
     /*   {
        page: 9,
        header: 'BEAFORCEFORGOOD',
        content: 'Stand behind the people who stand up for the right things.',
        attribution: 'Circul8',
    },
    {
        page: 10,
        header: 'WASTENOTWANTNOT',
        content: 'Budget. Time. Environment.<br>Be more resourceful with what we’ve got.',
        attribution: 'Circul8',
    },
    {
        page: 23,
        header: 'BEMORELIKEDOLLY',
        content: 'Captivate the whole f**king world.<br>Parton our French.',
        attribution: 'Circul8',
    },
        {
        page: 17,
        header: 'FINISHTHATNOVEL',
        content: 'Before you forget all the characters and have to start over.<br>Again.',
        attribution: 'Circul8',
    },
        {
        page: 23,
        header: 'CHOOSECAREFULLY',
        content: 'Work with people who strive to do amazing things everyday.',
        attribution: 'Circul8',
    },*/
];

export default data;
