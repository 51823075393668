<template>
    <div>
        <div class="darkBG">
            <div class="userForm slideDownCustom" v-if="displayForm">
                <FormComponent />
            </div>
        </div>

        <div :class="['flash flashy', { hide: hideFlash }]" :key="flash" />

        <transition enter-active-class="slideUpCustom" v-on:leave="slidingDown" v-on:after-enter="slidingUp">
            <div class="mainPage d-flex flex-column justify-content-center align-items-center" ref="one" v-if="!displayForm">
                <div class="wrapper" :key="keyValue">
                    <div>
                        <a href="https://www.circul8.com.au/?utm_source=Circul8&utm_medium=website&utm_campaign=2020_Vision">
                            <img src="../assets/circul-8-logo.svg" class="circul8" />
                        </a>
                        <div class="centerContent flashy">
                            <div class="mainImg flashy">
                                <Lottie v-bind:options="{ loop: true, autoplay: true }" />
                            </div>
                        </div>
                        <div class="borderWrapper">
                            <div v-if="true">
                                <div class="contentWrapper blur">
                                    <div v-bind:key="item.row" v-for="(item, ind) in this.renderSubcontent()">
                                        <div :class="[`line line-${ind}`, {'line--empty': str === '---'}]" v-for="(str, index) in item.content" v-bind:key="`${item.row}-${str}-${index}`">
                                            <div class="letter" v-for="(char, charIndex) in str" v-bind:key="`${item.row}-${char}-${index}-${charIndex}`">
                                                <p v-if="char === '$'" class="hiddenCharacter">{{ renderRandomLetter() }}</p>
                                                <p v-else>{{ char }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <p v-html="this.getContent().content" />
                                    </div>
                                </div>
                            </div>
                            <img src="../assets/ic-arrow.svg" class="arr arr--left" />
                            <img src="../assets/ic-arrow.svg" class="arr arr--right" />
                        </div>

                        <h5 class="companyName flashy">{{ this.getContent().attribution }}</h5>
                    </div>
                </div>
                <div class="footerText" @click="showForm">
                    <p>Add your vision</p>
                    <img src="../assets/icon-arrow-down.svg" class="iconArrowDown" />
                </div>
                <div class="arrow arrow--left" @click="changeContent('prev')" />
                <div class="arrow arrow--right" @click="changeContent()" />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import Lottie from './Lottie.vue';
import FormComponent from './FormComponent.vue';
import data from '../content';

export default {
    name: 'SecondPage',
    data() {
        return {
            keyValue: false,
            flash: false,
            hideFlash: false,
        };
    },
    components: {
        FormComponent,
        Lottie,
    },
    computed: mapState({
        id: state => state.page,
        displayForm: state => state.displayForm,
        contentAlias: 'displayForm',
    }),
    methods: {
        ...mapMutations(['incrementPage', 'decrementPage', 'toggleForm']),
        getContent() {
            const self = this;
            return data.find(item => item.page === self.id);
        },
        changeContent(directon = 'next') {
            try {
                const colours = ['#10e5bb', '#0fdcb4', '#f676ba', '#f6ec5d', '#7f75f6'];
                const flashyColour = colours[Math.floor(Math.random() * colours.length)];
                document.documentElement.style.setProperty('--flashy-colour', flashyColour);
            } catch (e) {
                // Do nothing in older browsers...
            }

            if (directon == 'prev') {
                this.$gtag.event('prev vision');
                this.decrementPage();
            } else {
                this.$gtag.event('next vision');
                this.incrementPage();
            }
            this.$forceUpdate();
            this.keyValue = !this.keyValue;
            this.flash = !this.flash;
        },
        renderRandomLetter() {
            let rnum = Math.floor(Math.random() * 25);
            let randomstring = 'abcdefghiklmnopqrstuvwxyz'.substring(rnum, rnum + 1);
            return randomstring;
        },
        renderSubcontent() {
            let header = this.getContent().header.replace(/\s+/g, '');
            let rows = [];
            let beginning = 0;
            let finishing = 0;
            for (let i = 0; i < 5; i++) {
                beginning = finishing;
                finishing = i + beginning + 1;
                const content = header.substring(beginning, finishing).split(' ');
                rows.push({ row: i, content: content[0] === '' ? ['---'] : content });
            }
            return rows;
        },
        showForm() {
            this.$gtag.event('add your vision');
            this.hideFlash = true;
            this.toggleForm();
        },
        slidingDown() {
            this.hideFlash = true;
        },
        slidingUp() {
            this.hideFlash = false;
        },
    },
};
</script>

<style>
:root {
    --flashy-colour: #10e5bb;
}
</style>

<style scoped lang="scss">
@import '@/assets/default.scss';
/* ----------------------------------------------------------------- */

.arrow {
    position: absolute;
    width: 50vw;
    height: 100vh;
    z-index: 100;

    &--left {
        top: 0;
        left: 0;
    }

    &--right {
        top: 0;
        right: 0;
    }
}

.blur {
    -webkit-animation: blur 0.5s linear backwards;
    -moz-animation: blur 0.5s linear backwards;
    -ms-animation: blur 0.5s linear backwards;
    -o-animation: blur 0.5s linear backwards;
    animation: blur 0.5s linear backwards;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}

@-webkit-keyframes blur {
    from {
        -webkit-filter: blur(5px);
        filter: blur(5px);
    }
}
@-moz-keyframes blur {
    from {
        -moz-filter: blur(5px);
        filter: blur(5px);
    }
}
@keyframes blur {
    from {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        filter: blur(5px);
    }
}

.slideUpCustom {
    -webkit-animation: slideUpCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) forwards;
    -moz-animation: slideUpCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) forwards;
    -ms-animation: slideUpCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) forwards;
    -o-animation: slideUpCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) forwards;
    animation: slideUpCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) forwards;
}

@keyframes slideUpCustom {
    from {
        transform: translateY(-100%);
    }
}

.slideDownCustom {
    -webkit-animation: slideDownCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) backwards;
    -moz-animation: slideDownCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) backwards;
    -ms-animation: slideDownCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) backwards;
    -o-animation: slideDownCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) backwards;
    animation: slideDownCustom 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) backwards;
}

@keyframes slideDownCustom {
    from {
        transform: translateY(100%);
    }
}

/* ----------------------------------------------------------------- */
.circul8 {
    width: 84px;
}
.wrapper {
    z-index: 2;
}
.mainPage {
    background-color: #000000;
    min-height: 100vh;
}
.darkBG {
    background-color: #000000;
}

.flashy {
    animation: flashAnim 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94) forwards;
    @keyframes flashAnim {
        0% {
            background-color: #10e5bb;
            background-color: var(--flashy-colour);
        }
        100% {
            background-color: #000;
        }
    }
}
.flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    &.hide {
        opacity: 0;
    }
}
.userForm {
    color: #000000 !important;
    height: 100vh;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
}
.borderWrapper {
    position: relative;
    border: solid 2px #ffffff;
    padding: 0 0px 0 0;
    margin: 0 auto;
    max-width: 740px;
}

.mainImg {
    width: 180px;
    height: 30px;
    border-radius: 17px;
    position: relative;
    top: 8px;
    background-color: #000000;
    object-fit: contain;
    padding: 0 30px;
    margin: 0 auto;
}
.content {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    height: 3rem;
    margin: 4vmin auto;
    color: $purple;
    padding: 0 1em;

    @media screen and (min-height: 825px) {
        font-size: 1rem;
        margin: 1.5em auto 2em;
    }
}
.content p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.companyName {
    display: inline-block;
    padding: 0 1rem;
    position: relative;
    top: -12px;
    background-color: #000000;
    margin: 0 auto;
    font-size: 16px;
    height: 17px;
}
.footerText {
    position: relative;
    z-index: 200;
    height: 17px;
    font-family: PublicaSlab-Bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    color: #848484;
    text-transform: uppercase;
    line-height: 30px;
    width: 230px;
    height: 45px;
    margin: 0 auto;
    margin-top: 0px;
    border-radius: 30px;
    padding: 10px;
    border-radius: 30px;
    cursor: grab;
    &:hover {
        background-color: #2b2b2b !important;
    }
}

.footerText p {
    display: inline-block;
    margin: 0;
}
.iconArrowDown {
    position: relative;
    right: -7px;
}
.hiddenCharacter {
    color: #2b2b2b;
}
.centerContent {
    position: relative;
    z-index: 2;
    max-width: 180px;
    margin: 0px auto;
    background: #000;
}

.letter {
    text-transform: uppercase;
    font-family: PublicaSlab-Medium;
    display: inline-block;

    p {
        margin-bottom: 1vh;
    }
}

.line {
    width: 100%;
    &--empty {
        visibility: hidden;
    }
}

.line-0 .letter {
    font-size: 23vmin;
    line-height: normal;
    margin-bottom: -5vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $red;

    @media screen and (min-height: 825px) {
        font-size: 180px;
        height: 171px;
    }
}

.line-1 .letter {
    font-size: 16.5vmin;
    line-height: 16.5vmin;
    letter-spacing: 3.8vh;
    margin-left: 3.8vh;
    width: 168px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    color: $orange;
    margin: 0 !important;

    @media screen and (min-height: 825px) {
        font-size: 120px;
        line-height: normal;
        height: 115px;
    }
}

.line-2 .letter {
    font-size: 10vmin;
    line-height: 10vmin;
    letter-spacing: 2.8vh;
    margin-left: 2.8vh;
    width: 118px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0 !important;
    color: $yellow;

    @media screen and (min-height: 825px) {
        font-size: 80px;
        line-height: normal;
        height: 89px;
    }
}

.line-3 .letter {
    font-size: 7vmin;
    line-height: 7vmin;
    margin-left: 3.2vh;
    width: 104px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    color: $green;
    margin: 0 !important;

    @media screen and (min-height: 825px) {
        font-size: 60px;
        line-height: normal;
        height: 77px;
    }
}

.line-4 .letter {
    font-size: 5vmin;
    line-height: 5vmin;
    width: 90px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    color: $blue;
    margin: 0 !important;


    @media screen and (min-height: 825px) {
        font-size: 50px;
        line-height: normal;
        height: 64px;
    }
}

.arr {
    position: absolute;
    top: 50%;
    margin-top: -10px;

    &--left {
        transform: rotate(180deg);
        left: 15px;
    }

    &--right {
        right: 15px;
    }
}

@media (max-width: 799px) {
    .mainImg {
        height: 30px;
    }
    .borderWrapper {
        padding: 0 !important;
    }
}

@media (max-width: 399px) {
    .content {
        font-size: 14px;
        margin: 10px auto 20px auto;
    }
    .circul8 {
        width: 65px;
    }
    .footerText {
        font-size: 12px;
        margin-top: -8px;
    }
    .mainImg {
        width: 126px;
        height: 24px;
        top: 8px;
    }
    .borderWrapper {
        max-width: 90%;
        padding: 0 !important;
    }
    .line-0 .letter {
        font-size: 97px;
        line-height: normal;
        width: 142px;
        height: 97px;
        letter-spacing: initial;
    }

    .line-1 .letter {
        width: 85px;
        height: 67px;
        font-size: 67px;
        letter-spacing: initial;
    }

    .line-2 .letter {
        width: 69px;
        height: 48px;
        font-size: 48px;
        letter-spacing: initial;
    }

    .line-3 .letter {
        width: 58px;
        height: 42px;
        font-size: 39px;
        letter-spacing: initial;
    }

    .line-4 .letter {
        width: 51px;
        height: 50px;
        font-size: 31px;
        letter-spacing: initial;
    }
}

@media (max-width: 600px) and (min-width: 400px) {
    .footerText {
        height: 17px;
        font-family: PublicaSlab-Bold;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
        text-align: center;
        color: #848484;
        text-transform: uppercase;
        line-height: 30px;
        width: 230px;
        height: 30px;
        margin: 0 auto;
        border-radius: 30px;
        padding: 10px;
        border-radius: 30px;
        cursor: -webkit-grab;
        cursor: grab;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #848484;
    }
    .content {
        margin-bottom: 31px;
    }
    .mainImg {
        width: 126px;
        height: 24px;
    }
    .borderWrapper {
        max-width: 90%;
    }
    .line-0 .letter {
        font-size: 130px;
        line-height: normal;
        width: 152px;
        height: 114px;
    }

    .line-1 .letter {
        font-size: 6vh;
        line-height: 6vh;
        // letter-spacing: 2vh;
        width: 118px;
        height: 79px;
        font-size: 80px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin: 0 !important;
    }

    .line-2 .letter {
        font-size: 4vh;
        line-height: 4vh;
        // letter-spacing: 2vh;
        margin-left: 2vh;
        width: 88px;
        height: 62px;
        font-size: 60px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin: 0 !important;
    }

    .line-3 .letter {
        font-size: 3.5vh;
        line-height: 3.5vh;
        // letter-spacing: 2vh;
        margin-left: 2vh;
        width: 70px;
        height: 56px;
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin: 0 !important;
    }

    .line-4 .letter {
        font-size: 3vh;
        line-height: 5vh;
        margin-left: 1.8vh;
        // letter-spacing: 1.8vh;
        width: 57px;
        height: 50px;
        font-size: 40px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 0 !important;
    }
}
</style>
