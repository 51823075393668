import Vue from 'vue';
import Vuex from 'vuex';
import {} from 'fs';
import data from '../content';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        page: 1,
        content: 'some content example',
        displayForm: false,
    },
    mutations: {
        decrementPage(state) {
            if (state.page == 2) {
                state.page = data.length;
            } else {
                state.page--;
            }
        },
        incrementPage(state) {
            if (state.page >= data.length) {
                state.page = 2;
            } else {
                state.page++;
            }
        },
        toggleForm(state) {
            state.displayForm = !state.displayForm;
        },
    },
    actions: {},
    modules: {},
});

export default store;
