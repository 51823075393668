<template>
    <div>
        <div class="footerWrapper" v-if="success === false">
            <div class="footerText" @click="closeForm">
                <p>Back to chart</p>
                <img src="../assets/icon-arrow-up.svg" class="iconArrowDown" />
            </div>
            <form @submit.prevent="handleSubmit">
                <div :class="[{ 'is-invalid': submitted && $v.user.vision.$error }, 'container', 'form-group']">
                    <div class="row justify-content-md-center">
                        <div class="col-6 text-left">
                            <label for="vision">Vision</label>
                        </div>
                        <div class="col-6 text-right">
                            <div class="textCounter">{{ this.$v.user.$model.vision.length || 0 }}/18</div>
                        </div>
                        <div class="col-12 text-center">
                            <input class="autoMargins" type="text" v-model="user.vision" id="vision" name="vision" placeholder="Enter Vision" maxlength="18" />
                        </div>
                    </div>
                </div>
                <div :class="[{ 'is-invalid': submitted && $v.user.caption.$error }, 'container', 'form-group']">
                    <div class="row justify-content-md-center">
                        <div class="col-6 text-left">
                            <label for="caption">Caption</label>
                        </div>
                        <div class="col-6 text-right">
                            <div class="textCounter">{{ this.$v.user.$model.caption.length || 0 }}/30</div>
                        </div>
                        <div class="col-12 text-center">
                            <textarea
                                type="text"
                                v-model="user.caption"
                                id="caption"
                                name="caption"
                                maxlength="30"
                                placeholder="Enter Vision Caption"
                                rows="2"
                            />
                        </div>
                    </div>
                </div>

                <div :class="[{ 'is-invalid': submitted && $v.user.nameOrCompany.$error }, 'container', 'form-group']">
                    <div class="row justify-content-md-center">
                        <div class="col-8 text-left">
                            <label for="nameOrCompany">Name or Company</label>
                        </div>
                        <div class="col-4 text-right">
                            <div class="textCounter">{{ this.$v.user.$model.nameOrCompany.length || 0 }}/30</div>
                        </div>
                        <div class="col-12 text-center">
                            <input
                                type="nameOrCompany"
                                v-model="user.nameOrCompany"
                                id="nameOrCompany"
                                name="nameOrCompany"
                                placeholder="Enter name or company name"
                                maxlength="30"
                            />
                        </div>
                    </div>
                </div>

                <div :class="[{ 'is-invalid': submitted && $v.user.email.$error }, 'container', 'form-group']">
                    <div class="row justify-content-md-center">
                        <div class="col-12 text-left">
                            <label for="email">Email (not visible to public)</label>
                        </div>
                        <div class="col-12 text-center">
                            <input v-model="user.email" id="email" name="email" placeholder="Enter email address" />
                        </div>
                    </div>
                </div>

                <div class="container form-group">
                    <div class="row justify-content-md-center align-items-center submitButton">
                        <button v-if="!saving">Add vision to chart</button>
                        <span v-else>Saving...</span>
                    </div>
                </div>
                <div class="container" v-if="apiError === true">
                    <div class="row is-invalid text-center">
                        <p class="autoMargins">Error submitting form please check fields and/or try again later</p>
                    </div>
                </div>
            </form>
        </div>
        <div class="submitWrapper footerWrapper" v-else>
            <div class="container mainWrapper">
                <div class="footerText success" @click="closeForm">
                    <p>Back to chart</p>
                    <img src="../assets/icon-arrow-up.svg" class="iconArrowDown" />
                </div>

                <div class="row">
                    <h2>
                        Thanks!
                        <br />Like 2020 Visions? <br />See more at projects at
                        <br />
                        <a href="https://www.circul8.com.au/?utm_source=Circul8&utm_medium=website&utm_campaign=2020_Vision">circul8.com.au</a>
                    </h2>
                </div>
                <div class="successFooter">
                    <div class="row">
                        <div class="submitButton">
                            <button disabled class="btn btn-primary">Added!</button>
                        </div>
                    </div>
                    <a href="https://www.circul8.com.au/?utm_source=Circul8&utm_medium=website&utm_campaign=2020_Vision" class="changeCursor">
                        <img src="../assets/circul-8.svg" class="circul8" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';
import { required, email, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'FormComponent',
    data() {
        return {
            user: {
                vision: '',
                caption: '',
                email: '',
                nameOrCompany: '',
            },
            submitted: false,
            success: false,
            apiError: false,
            saving: false,
        };
    },
    validations: {
        user: {
            vision: { required, maxLength: maxLength(18) },
            caption: { required, maxLength: maxLength(30) },
            nameOrCompany: { required, maxLength: maxLength(30) },
            email: { required, email },
        },
    },
    methods: {
        ...mapMutations(['toggleForm']),
        closeForm() {
            this.$gtag.event('close form');
            this.toggleForm();
        },
        async handleSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.saving = true;

            const utm_campaign = window.location.search.match(/(utm_campaign=([^&]*))/);
            const utm_source = window.location.search.match(/(utm_source=([^&]*))/);
            const utm_medium = window.location.search.match(/(utm_medium=([^&]*))/);

            const data = {
                utm_campaign: utm_campaign === null ? '2020Vision' : utm_campaign[2],
                utm_source: utm_source === null ? 'Circul8' : utm_source[2],
                utm_medium: utm_medium === null ? 'Website' : utm_medium[2],
                campaign: '5df7e02f43909adaa28ed681',
                Vision: this.$v.user.vision.$model,
                caption: this.$v.user.caption.$model,
                name: this.$v.user.nameOrCompany.$model,
                email: this.$v.user.email.$model,
            };

            try {
                const response = await axios.post('https://repopul8.circul8.com.au/api/save/', data);
                if (!response || (response && response.status !== 200) || (response && response.data.status !== 'ok')) {
                    this.apiError = true;
                    this.success = false;
                    this.$gtag.event('form error');
                } else {
                    this.apiError = false;
                    this.success = true;
                    this.$gtag.event('form submitted');
                }
            } catch (error) {
                this.apiError = true;
                this.success = false;
                this.$gtag.event('form error');
            } finally {
                this.saving = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
    .form-group {
        margin: 15px auto !important;
    }
    .form-group textarea {
        height: 80px !important;
    }
    .form-group input {
        height: 50px !important;
    }
}
@media (min-width: 800px) {
    .footerWrapper {
        padding: 20px;
    }
}
@media (min-width: 500px) {
    .labelAndWordWrapper,
    .form-group,
    .submitButton {
        max-width: 90%;
    }
}

@media (max-width: 600px) {
    .circul8 {
        display: none;
    }
    .form-group {
        width: 90%;
        margin: 20px auto !important;
    }
    .submitWrapper {
        .success {
            position: relative;
            top: -20vh;
        }
        .submitWrapper {
            width: 90%;
            margin: 0 auto;
            & h2 {
                padding-top: 0 !important;
            }
        }
        .submitButton {
            position: absolute;
            bottom: -22vh;
            max-width: 90%;
            margin: 0 auto;
            left: 3vh;
        }
    }
}

@media (max-width: 400px) {
    .form-group {
        input {
            height: 36px !important;
        }
        textarea {
            height: 40px !important;
        }
    }
    .mainWrapper {
        position: relative;
        top: -50px;
    }
    .submitWrapper h2 {
        padding-top: 0px !important;
        font-size: 20px !important;
        min-height: inherit !important;
    }

    .submitWrapper .success {
        top: -20vh !important;
    }
}

.footerWrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.submitWrapper {
    .success {
        position: relative;
        top: -10vh;
    }
    .successFooter {
        position: relative;
        bottom: -10vh !important;
    }
}
.changeCursor {
    cursor: grab;
}
.circul8 {
    height: 25px;
}

.inputWrapper {
    width: 100%;
}

.autoMargins {
    margin: 0 auto;
}

.submitWrapper h2 {
    min-height: 40vh;
    font-family: Arimo-Regular;
    margin: 0 auto;
    font-weight: normal;
    font-size: 26px;
    padding-top: 10vh;
    & a {
        font-weight: bold;
        text-decoration: none;
        color: #000 !important;
    }
}

.labelAndWordWrapper .textCounter {
    font-size: 14px;
    display: inline-block;
}
.is-invalid {
    color: #fb332e !important;
    & input,
    textarea {
        border-color: #fb332e !important;
        border-width: 4px !important;
        &:focus {
            outline: none !important;
        }
        &::placeholder {
            color: #fb332e !important;
        }
    }
}

.form-group {
    max-width: 420px;
    width: 100%;
    margin: 30px auto;
    padding: 0 20px;
    & input {
        width: 100%;
        height: 64px;
        max-width: 384px;
        border: solid 2px #000;
        font-size: 14px;
        padding: 0 10px;
        &:focus {
            outline: 4px solid #000000;
        }
    }
    & textarea {
        width: 100%;
        height: 90px;
        max-width: 384px;
        border: solid 2px #000;
        font-size: 14px;
        padding: 10px 10px;
        resize: none;
        &:focus {
            outline: 4px solid #000000;
        }
    }
    & label {
        display: inline-block;
        display: inline-block;
        font-size: 14px;
        font-family: 'Arimo-Bold';
    }
    .textCounter {
        font-size: 14px;
        display: inline-block;
    }
}
.invalid-feedback {
    color: red;
}
.userForm {
    padding: 0 15px;
}
.footerText {
    height: 17px;
    padding-top: 10px;
    font-family: PublicaSlab-Bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    color: #848484;
    text-transform: uppercase;
    line-height: 30px;
    width: 230px;
    height: 50px;
    margin: 0 auto;
    border-radius: 30px;
    cursor: -webkit-grab;
    cursor: grab;
    padding-top: 10px;
    cursor: grab;
    &:hover {
        background-color: #2b2b2b !important;
    }
    & img {
        position: relative;
        left: 5px;
    }
}

.submitButton {
    font-family: PublicaSlab-Bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
    text-transform: uppercase;
    line-height: 30px;
    border-radius: 30px;
    cursor: grab;
    max-width: 384px;
    width: 100%;
    margin: 6vh auto 6vh auto;
    padding: 0 20px;
    height: 64px;
    &:hover {
        background-color: #2b2b2b !important;
    }
    & button {
        background-color: inherit;
        border: none;
        color: inherit;
        cursor: inherit;
        font-size: inherit;
        text-transform: uppercase;
        font-family: inherit;
        height: 100%;
        padding: 0;
        letter-spacing: inherit;
        width: 100%;
        border-radius: 30px;
    }
}

.footerText p {
    display: inline-block;
    margin: 0;
    padding-bottom: 10px;
}
</style>
